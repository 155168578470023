import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faClipboardListCheck as farClipboardListCheck,
  faClipboard as farClipboard,
  faExclamationTriangle as farExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faKey as fasKey,
  faTools as fasTools,
  faTimes as fasTimes,
  faSyncAlt as fasSyncAlt,
  faCheck as fasCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { faUser as fadUser } from '@fortawesome/pro-duotone-svg-icons';

import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

library.add(farClipboardListCheck);
library.add(farClipboard);
library.add(farExclamationTriangle);
library.add(fasTools);
library.add(fadUser);
library.add(fasKey);
library.add(fasTimes);
library.add(fasSyncAlt);
library.add(fasCheck);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
Vue.component('fa-layers-text', FontAwesomeLayersText);
