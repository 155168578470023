import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons, BVConfigPlugin } from 'bootstrap-vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueI18n from 'vue-i18n';
import InputFacade from 'vue-input-facade';
import VueSimpleSuggest from 'vue-simple-suggest/lib';

import { getSessionInformation } from '@/api/auth-api';
import MainLayout from '@/layouts/MainLayout.vue';
import PublicLayout from '@/layouts/PublicLayout.vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import './plugins/fontawesome';
import './plugins/vue-multiselect';
import './plugins/class-component-hooks';
import './plugins/vee-validate';
import './plugins/vue2-datepicker';

import './directives/focus-next-on-enter';

import './main.scss';

Vue.component('main-layout', MainLayout);
Vue.component('public-layout', PublicLayout);

Vue.use(PiniaVuePlugin);

// Configure tooltips
Vue.use(BVConfigPlugin, {
  BTooltip: {
    delay: {
      show: 300,
      hide: 100,
    },
  },
  BPopover: {
    delay: 1000,
  },
});

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons);

Vue.use(VueI18n);

Vue.use(InputFacade);

Vue.component('vue-simple-suggest', VueSimpleSuggest);

Vue.config.productionTip = false;

(async () => {
  const result = await getSessionInformation();
  const pinia = createPinia();

  store.commit('setIsLoggedIn', result.isAuthenticated);
  store.commit('setUserGuid', result.userGuid);
  store.commit('setUsername', result.username);
  store.commit('setFullName', result.fullName);
  store.commit('setUserRoles', result.roles);

  new Vue({
    router,
    store,
    pinia,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
})();
