export class Constants {
  // Materials requisition note
  public MRN = 'MRN'
  // Material transfer mote
  public MTN = 'MTN'
  // Goods received note
  public GRN = 'GRN'
  public BCO = 'BCO'
  public BTO = 'BTO'
  // Nota de casare obiecte de inventar
  public IDN = 'IDN'

  public inventoryOrderCriteria = {
    denumire: {
      value: 0,
      name: 'Denumire',
    },
    codProdus: {
      value: 1,
      name: 'Cod Produs',
    },
  }

  public vatPercentageOptions = [
    { label: '19 %', key: 0.19 },
    { label: '9 %', key: 0.09 },
    { label: '5 %', key: 0.05 },
    { label: '0 %', key: 0.0 },
  ]

  public productCatalogModalState = {
    view: 1,
    edit: 2,
    add: 3,
    addExternal: 4,
  }

  productClassTypes = {
    product: 'PDT',
    service: 'SVC',
  }

  userAccountTypes = {
    admin: 'InvAppAdmin',
    user: 'InvAppUser',
  }
}

export const constants = new Constants();
