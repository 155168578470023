























































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import NavbarLink from '@/components/NavbarLink.vue';
import NavbarSection from '@/components/NavbarSection.vue';
import PredefinedIconstack from '@/components/PredefinedIconstack.vue';
import { mapGetters } from 'vuex';

@Component({
  components: { NavbarLink, NavbarSection, PredefinedIconstack },
  computed: {
    ...mapGetters(['canAdminister']),
  },
  name: 'MainLayout',
})
export default class MainLayout extends Vue {
  get breadcrumbGroup() {
    return this.$store.state.breadcrumbGroup;
  }
  get breadcrumbGroupRoute() {
    return this.$store.state.breadcrumbGroupRoute;
  }
  get breadcrumbItem() {
    return this.$store.state.breadcrumbItem;
  }

  get fullName() {
    return this.$store.state.fullName;
  }
}
