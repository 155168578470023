import Vue, { DirectiveOptions } from 'vue';

// We need to monitor this for the vue-multiselect close events
// We could use this if we wanted to listen to the 'close' event instead
// const pressedKeys = {};
// window.onkeyup = (e) => { pressedKeys[e.keyCode] = false; };
// window.onkeydown = (e) => { pressedKeys[e.keyCode] = true; };

function perform(el, binding, vnode, ev) {
  try {
    const refNames = Array.isArray(binding.value)
      ? binding.value
      : [binding.value];

    const targetRefName = refNames.find((refName) => {
      if (typeof refName === 'string') {
        const $refCandidate = vnode.context?.$refs[refName];
        if ($refCandidate !== null && $refCandidate !== undefined) {
          if ((<any>$refCandidate).disabled === true) {
            return false;
          }
          return true;
        }
      }

      return false;
    });

    const $ref = targetRefName
      ? vnode.context?.$refs[targetRefName]
      : undefined;

    if ($ref !== undefined && $ref !== null) {
      (<HTMLElement>$ref).focus();
      if ($ref instanceof HTMLInputElement) {
        (<HTMLInputElement>$ref).select();
      } else if ($ref instanceof Vue && ((<Vue>$ref)).$el instanceof HTMLInputElement) {
        (<HTMLInputElement>(<Vue>$ref).$el).select();
      }
    } else {
      let target = ev.target as HTMLElement;
      // If we're in a multiselect, the current element is the <div> ; we need to get to the <input> inside it
      target = target.querySelector('input.multiselect__input') as HTMLElement || target;

      // First try to find a container, otherwise look for a form
      const container = target.closest('.focus-next-on-enter-container') ?? target.closest('form');
      const formControls = container?.querySelectorAll('input, select, textarea, button');

      if (formControls === undefined) {
        return undefined;
      }

      let idx = 0;
      for (; idx < formControls.length; idx += 1) {
        if (formControls[idx] === target) {
          idx += 1;
          break;
        }
      }

      // Next let's find the first non-disabled element
      for (; idx < formControls.length; idx += 1) {
        if ((<any>formControls[idx]).disabled === false) {
          break;
        }
      }

      if (idx >= formControls.length) {
        return undefined;
      }

      (formControls[idx] as HTMLElement).focus();
      if (formControls[idx] instanceof HTMLInputElement) {
        (formControls[idx] as HTMLInputElement).select();
      }
    }

    ev.preventDefault();
    return false;
  } catch (ex) {
    // Just do nothing if we can't refocus, it's not a big deal
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(ex);
    }

    return undefined;
  }
}

const directive: DirectiveOptions = {
  // This is added to make the directive compatible with vue-multiselect
  bind: (el, binding, vnode) => {
    if (!vnode.componentInstance || !vnode.componentOptions) {
      return;
    }

    if (vnode.componentOptions.tag !== 'multiselect') {
      // We handle this through 'inserted' below
      return;
    }

    vnode.componentInstance.$on('select', () => {
      if (!vnode.componentInstance) {
        return;
      }

      // Shift-tab prevents this from executing as we want to travel backwards
      // We could use this if we wanted to listen to the 'close' event instead
      // if (pressedKeys[16] && pressedKeys[9]) {
      //   return;
      // }

      perform(el, binding, vnode, {
        target: vnode.componentInstance.$el,
        preventDefault() {
          //
        },
      });
    });
  },
  inserted: (el, binding, vnode): boolean|void => {
    el.addEventListener('keydown', (ev: KeyboardEvent) => {
      // We also allow child elements to target this handler
      if (ev.key !== 'Enter' && ev.key !== 'Tab') {
        return undefined;
      }

      // Allow TAB-bing backwards and shift-enter (for textarea)
      if (ev.shiftKey) {
        return undefined;
      }

      return perform(el, binding, vnode, ev);
    });
  },
};

// Name is a bit misleading as we're also using the same feature for TAB for now, which
// might change in the future as it's not really required to handle that one
Vue.directive('focus-next-on-enter', directive);
