import { configure, localize, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import ro from 'vee-validate/dist/locale/ro.json';

// Remove field names for now
const simplifiedRo = {
  ...ro,
  messages: Object.keys(ro.messages).reduce((acc, x) => ({
    ...acc,
    [x]: ro.messages[x].replace(/\s*\{_field_\}/g, ''),
  }), {}),
};

// console.log(ro.messages);

// No message specified.
extend('required', required);

localize('ro', simplifiedRo);

const config = {
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
  bails: false,
  skipOptional: true,
  mode: 'eager', // see: https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
  useConstraintAttrs: true,
};

// Sets the options.
configure(config);
