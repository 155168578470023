import axios from 'axios';
import Cookies from 'js-cookie';
import { parse, stringify } from 'qs';

const instance = axios.create({
  // Set a hefty default timeout
  timeout: 5000,
  paramsSerializer: {
    encode: parse,
    serialize: (params) => stringify(params, { arrayFormat: 'repeat' }),
  },
  //
  // We could just use these instead of an interceptor:
  //
  // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  // xsrfCookieName: 'XSRF-TOKEN', // default
  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  // xsrfHeaderName: 'X-XSRF-TOKEN', // default
});

instance.interceptors.request.use((config) => {
  const csrfCookie = Cookies.get('XSRF-TOKEN');
  if (csrfCookie) {
    // eslint-disable-next-line no-param-reassign
    config.headers['X-XSRF-TOKEN'] = csrfCookie;
  }

  return config;
}, null, { synchronous: true });

export default instance;
